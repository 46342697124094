<template>
  <div>UPI - Enter transaction Id</div>
</template>

<script>
import { mapGetters } from "vuex";
import shortUid from "@lib/utility/shortUid";

export default {
  computed: {
    ...mapGetters(["store", "primaryColor", "storeLogo"]),
  },
  async mounted() {
    console.log("UPI Manual module - client component");
  },
};
</script>
