// Register all payment modules - primarily stores and event listeners
// Pre registration hook
import { StorefrontModule } from "@lib/appRegister";
import { upiManualStore } from "./store";

export const UpiManualModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig,
}) {
  store.registerModule("payment/upi-manual", upiManualStore);
  store.dispatch("payment/upi-manual/init");
};
